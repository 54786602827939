import { loginFromToken } from './loginAPI';
import { setUserStore, getUserStore } from '../stores/userStore';

export default {
    queue: [],
    isRefreshing: false,
    queueId: 1,

    fetch(input, init) {
        return new Promise((resolve, reject) => {
            const request = this.createRequest(input, init);
            this.add(request, (error, response) => {
                if (error) {
                    return reject(error);
                }
                resolve(response);
            })
        })
    },

    createRequest(input, init) {
        const request = new Request(input, init);
        request._queueId = this.queueId++;
        return request;
    },

    add(request, callback) {
        this.queue.push({ request, callback });

        if (this.isRefreshing) {
            return
        }
        this.processRequests();
    },

    async processRequests() {
        const promises = this.queue.map(entry => this._fetch(entry.request));
        const responses = await Promise.all(promises);
        const completed = this.removeCompleted(responses);

        if (this.isRefreshing) {
            this.refreshToken();
        }
        completed.forEach(entry => entry.callback(entry.error, entry.response));
    },

    removeCompleted(responses) {
        let completed = [];
        let pending = [];

        for (let i = 0; i < responses.length; i++) {
            let response = responses[i].value;
            let promiseStatus = responses[i].status;

            let entry = this.queue.find(entry => entry.request._queueId === response._queueId);
            if (!entry) {
                continue
            }

            if (promiseStatus === 'rejected') {
                completed.push({
                    error: new Error('network error'),
                    response: null,
                    callback: entry.callback
                })
            }
            else {
                if (response.status === 401) {
                    this.isRefreshing = true;
                    pending.push(entry)
                }
                else {
                    completed.push({
                        error: null,
                        response: response,
                        callback: entry.callback
                    })
                }
            }
        }
        this.queue = pending;
        return completed;
    },

    refreshToken() {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            return
        };

        loginFromToken(refreshToken)
            .then(({ err, data }) => {
                if (err) {
                    throw new Error(err.message);
                }
                setUserStore(data.accessToken);
                this.isRefreshing = false;
                this.processRequests();
            })
            .catch((err) => {
                window.location.reload();
            })
    },

    _fetch(request) {
        request.headers.set('token', getUserStore().accessToken)
        return fetch(request)
            .then((response) => {
                response._queueId = request._queueId;
                return {
                    status: 'fulfilled',
                    value: response
                }
            })
            .catch((err) => {
                return {
                    status: 'rejected',
                    value: request
                }
            })
    }


};

