import {basePath} from '../settings';

export function loginMs (accessToken) {
    const url = basePath +  '/loginMs';
    return login(accessToken, url);
}

export function loginFromToken (refreshToken) {
    const url = basePath +  '/login';
    return login(refreshToken, url);
}

async function login (accessToken, url) {
    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            accessToken: accessToken
        }),
        headers: {
            'content-type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('login failed');
    }
    return response.json();
}