import React from 'react';
import { getUserStore } from '../../stores/userStore';
import { sidemenuStore } from '../../stores/sidemenuStore';
import './Sidemenu.css';

class Sidemenu extends React.Component {
    createSidemenu() {
        const user = getUserStore();
        return sidemenuStore.map((item) => {
            if (this.checkRoles(user.roles, item.roles)) {
                if (!item.subitems) {
                    return (
                        <ul key={item.id}>
                            <li onClick={() => this.selectMenu(item.view)}>
                                {item.icon} {item.displayName}
                            </li>
                        </ul>
                    )
                }
                else {
                    return (
                        <ul key={item.id}>
                            <li>
                                {item.icon} {item.displayName}
                            </li>
                            <li>
                                <ul className="submenu">{this.createSubmenu(item, user.roles)}</ul>
                            </li>
                        </ul>
                    )
                }
            };
        })
    }

    createSubmenu(item, userRoles) {
        return item.subitems.map((subitem) => {
            if (this.checkRoles(subitem.roles, userRoles)) {
                return (
                    <li
                        key={subitem.id}
                        onClick={() => this.selectMenu(subitem.view)}
                    >
                        {subitem.displayName}
                    </li>
                )
            }
        })
    }

    checkRoles(itemRoles, userRoles) {
        return itemRoles.some(role => userRoles.includes(role));
    }

    selectMenu(itemView) {
        this.props.selectView(itemView);
    }

    render() {
        return (
            <ul className='sidemenu'>
                {this.createSidemenu()}
            </ul>
        )
    }
}

export default Sidemenu;
