import { basePath } from '../settings';
import { getUserStore } from '../stores/userStore';
import http from './http';

export async function getCandidates() {
    const user = getUserStore();
    const url = basePath + '/candidates';

    const response = await http.fetch(url);
    return response.json();
}

export async function getCandidateNotes(candidateId) {
    const user = getUserStore();
    const url = basePath + '/candidates/candidate/' + candidateId + '/notes';

    const response = await http.fetch(url);

    return response.json();
}

export async function patchCandidate(formData) {
    const user = getUserStore();
    const url = basePath + '/candidates/';

    const response = await http.fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(formData),
        headers: {
            'content-type': 'application/json'
        }
    });

    return response.json();
}

export async function getCandidateStates() {
    const url = basePath + '/states';
    const response = await http.fetch(url);
    return response.json();
}

export async function postComment(id, comment) {
    const url = basePath + '/candidates/candidate/' + id + '/notes';
    const response = await http.fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            newComment: comment
        }),
        headers: {
            'content-type': 'application/json'
        }
    });
    return response.json();
}

