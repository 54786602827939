import React from 'react';
import sigla1 from '../../img/sigla1.png';
import './Header.css';

class Header extends React.Component {
	render() {
		return (
			<div>
				<header className="header">
					<img src={sigla1} className="logo" alt="logo" />
					<h1 className="title">Platforma Voluntarului - Admin</h1>
				</header>
			</div>
		);
	}
}

export default Header;
