import jwt from "jsonwebtoken";

let userStore = {
    id: -1,
    firstName: "",
    lastName: "",
    fullname: "",
    email: "",
    rrcEmail: "",
    telephone: -1,
    birthday: "",
    picture: "",
    contract: -1,
    signDate: "",
    expiryDate: "",
    branchId: -1,
    branchName: "",
    branchShortName: "",
    roles: [],
    accessToken: ""
}

export function setUserStore(accessToken) {
    let decoded = jwt.decode(accessToken);
    userStore = decoded;
    userStore.accessToken = accessToken;
}

export function getUserStore() {
    return userStore;
}