import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Announcement from '@material-ui/icons/Announcement';
import Computer from '@material-ui/icons/Computer';
import BarChart from '@material-ui/icons/BarChart';
import Candidates from '../views/Candidates';

export const sidemenuStore = [
    {
        'id': 1,
        'displayName': 'Utilizatori',
        'roles': [1, 2, 3, 4, 5, 6, 7, 8, 9],
        'icon': <AccountCircle />,
        'subitems': [
            {
                'id': 1.1,
                'displayName': 'Candidati',
                'roles': [1, 2, 3, 4, 5, 6, 7, 8, 9],
                'view': <Candidates />
            },
            {
                'id': 1.2,
                'displayName': 'Voluntari',
                'roles': [1, 2, 3, 4, 5, 6, 7, 8, 9],
                'view': "Volunteers"
            },
            {
                'id': 1.3,
                'displayName': 'Admini',
                'roles': [1, 2, 3, 4, 5, 6, 7, 8, 9],
                'view': "Admins"
            }
        ]
    },
    {
        'id': 2,
        'displayName': 'Activitati',
        'roles': [1, 2, 3, 4, 5, 6, 7, 8, 9],
        'icon': <CalendarToday />,
        'subitems': [
            {
                'id': 2.1,
                'displayName': 'Activitati trecute',
                'roles': [1, 2, 3, 4, 5, 6, 7, 8, 9],
                'view': "PastActivities"
            },
            {
                'id': 2.2,
                'displayName': 'Activitati viitoare',
                'roles': [1, 2, 3, 4, 5, 6, 7, 8, 9],
                'view': "FutureActivities"
            },
            {
                'id': 2.3,
                'displayName': 'Validare prezenta',
                'roles': [1, 2, 3, 4, 5, 6, 7, 8, 9],
                'view': "ValidatePresence"
            }
        ]
    },
    {
        'id': 3,
        'displayName': 'Anunturi',
        'roles': [1, 2, 3, 4, 5, 6, 7, 8, 9],
        'icon': <Announcement />,
        'view': "Announcements"
    },
    {
        'id': 4,
        'displayName': 'Cursuri',
        'roles': [1, 2, 3, 4, 5, 6, 7, 8, 9],
        'icon': <Computer />,
        'view': "Courses"
    },
    {
        'id': 5,
        'displayName': 'Rapoarte',
        'roles': [1, 2, 3, 4, 5, 6, 7, 8, 9],
        'icon': <BarChart />,
        'view': "Reports"
    }
]