import React from "react";
import MicrosoftLogin from "react-microsoft-login";
import { loginMs } from '../../api/loginAPI';
import { setUserStore } from '../../stores/userStore';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.authHandler = this.authHandler.bind(this);
    }

    authHandler() {
        loginMs(sessionStorage["msal.idtoken"])
            .then(({ err, data }) => {
                if (err) {
                    throw new Error(err.message);
                }
                setUserStore(data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken);
                this.props.onLogin(true);
            })
            .catch((err) => {
                this.props.onLogin(false);
            })
    }

    render() {
        return (
            <MicrosoftLogin
                clientId="009d0fb1-ea20-47a1-9f30-8702cb9d7ecb"
                authCallback={this.authHandler}
            />
        )
    }
}

export default Login;