import React from 'react';
import { getCandidates, getCandidateNotes, getCandidateStates, patchCandidate, postComment } from '../api/candidatesAPI';
import { getUserStore } from '../stores/userStore';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator';

const columns = [
    {
        title: 'Id candidat',
        field: 'candidate_id',
        visible: false
    },
    {
        title: 'Status',
        field: 'state_name',
        headerFilter: true
    },
    {
        title: 'Data',
        field: 'date',
        headerFilter: true
    },
    {
        title: 'Prenume',
        field: 'firstname',
        headerFilter: true
    },
    {
        title: 'Nume',
        field: 'lastname',
        headerFilter: true
    },
    {
        title: 'Email',
        field: 'email',
        headerFilter: true
    },
    {
        title: 'Telefon',
        field: 'telephone',
        headerFilter: true
    }
]

const columnsComment = [
    {
        title: 'Id comentariu',
        field: 'comment_id',
        visible: false
    },
    {
        title: 'Data',
        field: 'date',
        headerFilter: true
    },
    {
        title: 'Prenume',
        field: 'firstname',
        headerFilter: true
    },
    {
        title: 'Nume',
        field: 'lastname',
        headerFilter: true
    },
    {
        title: 'Comentariu',
        field: 'note',
        headerFilter: true
    }

]

// const Data = [
//     {
//         branch_name: "Sector 6",
//         date: "19-11-2021",
//         email: "ilie_marius1989@yahoo.com",
//         firstname: "Ion",
//         candidate_id: 1,
//         lastname: "Marius",
//         telephone: "0724539581",
//         state_name: "Confirmat"
//     },
//     {
//         branch_name: "Sector 6",
//         date: "19-11-2021",
//         email: "ilie_marius1989@yahoo.com",
//         firstname: "Ilie",
//         candidate_id: 5,
//         lastname: "Marius",
//         telephone: "0724539581",
//         state_name: "Confirmat"
//     },
//     {
//         branch_name: "Sector 6",
//         date: "19-11-2021",
//         email: "ilie_marius1989@yahoo.com",
//         firstname: "Ilie",
//         candidate_id: 5555,
//         lastname: "Marius",
//         telephone: "0724539581",
//         state_name: "Confirmat"
//     },
//     {
//         branch_name: "Sector 6",
//         date: "19-11-2021",
//         email: "ilie_marius1989@yahoo.com",
//         firstname: "Ilie",
//         candidate_id: 555,
//         lastname: "Marius",
//         telephone: "0724539581",
//         state_name: "Confirmat"
//     },
//     {
//         branch_name: "Sector 6",
//         date: "19-11-2021",
//         email: "ilie_marius1989@yahoo.com",
//         firstname: "Ilie",
//         candidate_id: 55,
//         lastname: "Marius",
//         telephone: "0724539581",
//         state_name: "Confirmat"
//     },
//     {
//         branch_name: "Sector 6",
//         date: "19-11-2021",
//         email: "ilie_marius1989@yahoo.com",
//         firstname: "Ilie",
//         candidate_id: 6,
//         lastname: "Marius",
//         telephone: "0724539581",
//         state_name: "Confirmat"
//     },
//     {
//         branch_name: "Sector 6",
//         date: "19-11-2021",
//         email: "ilie_marius1989@yahoo.com",
//         firstname: "Alex",
//         candidate_id: 4,
//         lastname: "Marius",
//         telephone: "0724539581",
//         state_name: "Confirmat"
//     }
// ]

class Candidates extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            candidates: [],
            selectedCandidate: {},
            formClass: 'hide',
            formData: {
                state_id: '',
                id: -1,
                date: '',
                email: '',
                firstname: '',
                lastname: '',
                telephone: '',
            },
            commentsData: [],
            newComment: ''
        }
        this.editRow = this.editRow.bind(this);
        this.getNotes = this.getNotes.bind(this);
        this.setFormData = this.setFormData.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
        this.setNewComment = this.setNewComment.bind(this);
        this.addComment = this.addComment.bind(this);
    }

    async componentDidMount() {
        const content = await getCandidates();
        const states = await getCandidateStates();

        //logica pentru eroare
        this.setState({
            candidates: content.data,
            states: this.buildOptions(states.data)
        })
    }

    buildOptions(states) {
        let items = [];

        states.forEach(state => {
            items.push(<option key={state.state_id} value={state.state_id}>{state.state_name}</option>);
        })

        return items;
    }

    editRow(e, row) {
        if (!e.ctrlKey) {
            return
        };
        this.getNotes(e, row);
        e.preventDefault();
        this.setState({
            formClass: 'show',
            formData: row.getData()
        })

    }

    async getNotes(e, row) {
        const candidateId = row.getData().candidate_id;
        const content = await getCandidateNotes(candidateId);
        this.setState({
            commentsData: content.data
        })
    }

    setFormData(e) {
        const { value, name } = e.target;
        const formData = Object.assign({}, this.state.formData, { [name]: value });
        this.setState({ formData });
    }

    submitFormData() {
        patchCandidate(this.state.formData);
    }

    setNewComment(e) {
        this.setState({
            newComment: e.target.value
        })
    }

    addComment() {
        if (this.state.newComment.length > 0) {
            postComment(this.state.formData.candidate_id, this.state.newComment)
                .then(async res => {
                    if (!res.err) {
                        const content = await getCandidateNotes(this.state.formData.candidate_id);
                        this.setState({
                            commentsData: content.data,
                            newComment: ''
                        })
                    }
                })
        }
        else {
            alert('Comentariu lipsa')
        }

    }

    render() {
        const smallTableOptions = {
            pagination: 'local',
            paginationSize: 5,
            dataTree: true
        }

        const bigTableOptions = {
            pagination: 'local',
            paginationSize: 20,
            dataTree: true
        }

        return (
            <div>
                <h1 className='pageTitle'>Candidati</h1>
                <div className={this.state.formClass}>
                    <div className='form'>
                        <div>
                            <label htmlFor='state_id'>Status</label>
                            <select name='state_id' required value={this.state.formData.state_id} onChange={this.setFormData}>
                                {this.state.states}
                            </select>
                        </div>
                        <div>
                            <label htmlFor='firstname'>Prenume</label>
                            <input type='text' name='firstname' required value={this.state.formData.firstname} onChange={this.setFormData}></input>
                        </div>
                        <div>
                            <label htmlFor='lastname'>Nume</label>
                            <input type='text' name='lastname' required value={this.state.formData.lastname} onChange={this.setFormData}></input>
                        </div>
                        <div>
                            <label htmlFor='email'>Email</label>
                            <input type='text' name='email' required value={this.state.formData.email} onChange={this.setFormData}></input>
                        </div>
                        <div>
                            <label htmlFor='telephone'>Telefon</label>
                            <input type='text' name='telephone' required value={this.state.formData.telephone} onChange={this.setFormData}></input>
                        </div>
                        <button onClick={this.submitFormData}>Submit</button>
                    </div>
                    <div className='detailsTable'>
                        <ReactTabulator
                            className='table'
                            layout={'fitColumns'}
                            columns={columnsComment}
                            data={this.state.commentsData}
                            options={smallTableOptions}
                            addRowPos={"bottom"}
                        />
                        <div>
                            <label htmlFor='comment'>Comentariu</label>
                            <input type='text' name='comment' required value={this.state.newComment} onChange={this.setNewComment}></input>
                            <button onClick={this.addComment}>Adauga comentariu</button>
                        </div>
                    </div>
                </div>
                <ReactTabulator
                    className='table'
                    layout={'fitColumns'}
                    columns={columns}
                    data={this.state.candidates}
                    //data={Data}
                    options={bigTableOptions}
                    rowClick={this.editRow}
                    rowDblClick={this.getNotes}
                />
            </div>

        );
    }
}

export default Candidates;
