import React from 'react';
import Header from '../header/Header';
import Login from '../login/Login';
import Sidemenu from '../sidemenu/Sidemenu';
import View from '../view/View';
import { loginFromToken } from '../../api/loginAPI';
import { setUserStore } from '../../stores/userStore';
import './App.css';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            logged: false,
            selectedView: <div>Hello</div>
        }
        this.onLogin = this.onLogin.bind(this);
        this.selectView = this.selectView.bind(this);
    }

    componentDidMount() {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            return
        }

        loginFromToken(refreshToken)
            .then(({ err, data }) => {
                if (err) {
                    throw new Error(err.message);
                }
                setUserStore(data.accessToken);
                this.setState({
                    logged: true
                })
            })
            .catch((err) => {
                this.setState({
                    logged: false
                })
            })
    }

    onLogin(logged) {
        this.setState({
            logged: logged
        })
    }

    selectView(view) {
        this.setState({
            selectedView: view
        })
    }

    render() {

        if (this.state.logged) {
            return (
                <div className="App">
                    <Header />
                    <Sidemenu selectView={this.selectView} />
                    <View selectedView={this.state.selectedView} />
                </div>
            );
        }
        else {
            return (
                <div className="App">
                    <Header />
                    <Login onLogin={this.onLogin} />
                </div>
            )
        }
    }
}

export default App;
