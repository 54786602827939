import React from 'react';
import './View.css';

class View extends React.Component {
	render() {
		return (
            <div className = "view">
				{this.props.selectedView}
            </div>
		);
	}
}

export default View;
